@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;700&family=Lato&display=swap');

:root {
  --font-primary: "Rubik", sans-serif;
  --font-seconday: "Lato", serif;
}

@mixin heading {
}

@mixin heading1 {
  @include heading;
  font-family: var(--font-seconday);
  font-size: 3rem;
  line-height: 0.8;
  margin-bottom: var(--space-2x);
}

@mixin heading2 {
  @include heading;
  font-family: var(--font-seconday);
  font-size: 1.5rem;
  line-height: 0.8;
  margin-bottom: var(--space-2x);
}

@mixin heading3 {
  @include heading;
  font-family: var(--font-seconday);
  font-size: 1.2rem;
  line-height: 0.8;
}

@mixin heading4 {
  @include heading;
  font-family: var(--font-seconday);
  font-size: 1rem;
  line-height: 0.8;
}

@mixin em {
  font-size: 0.7rem;
}

@mixin strong {
  font-weight: bold;
}

@mixin link {
  text-decoration: none;
  color: var(--color-primary);
  font-size: 1em;
  position: relative;
  display: inline-block;
}

@mixin linkHover {
  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: calc(-1 * var(--space-2x));
    width: 100%;
    height: 1px;
    background: var(--color-primary);
    transition: opacity 0.3s, transform 0.3s;
    opacity: 0;
    transform: scale(0, 1);
  }

  &:hover::after {
    opacity: 1;
    transform: scale(1);
  }
}
