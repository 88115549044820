:root {
  --platinum: #eadedaff;
  --rocket-metallic: #998888ff;
  --raspberry: #db2955ff;
  --green-munsell: #ffd176;
  --space-cadet: #2b2d42ff;

  /* SCSS Gradient */
  $gradient-top: linear-gradient(0deg, #eadedaff, #998888ff, #db2955ff, #04a777ff, #2b2d42ff);
  $gradient-right: linear-gradient(90deg, #eadedaff, #998888ff, #db2955ff, #04a777ff, #2b2d42ff);
  $gradient-bottom: linear-gradient(180deg, #eadedaff, #998888ff, #db2955ff, #04a777ff, #2b2d42ff);
  $gradient-left: linear-gradient(270deg, #eadedaff, #998888ff, #db2955ff, #04a777ff, #2b2d42ff);
  $gradient-top-right: linear-gradient(45deg, #eadedaff, #998888ff, #db2955ff, #04a777ff, #2b2d42ff);
  $gradient-bottom-right: linear-gradient(135deg, #eadedaff, #998888ff, #db2955ff, #04a777ff, #2b2d42ff);
  $gradient-top-left: linear-gradient(225deg, #eadedaff, #998888ff, #db2955ff, #04a777ff, #2b2d42ff);
  $gradient-bottom-left: linear-gradient(315deg, #eadedaff, #998888ff, #db2955ff, #04a777ff, #2b2d42ff);
  $gradient-radial: radial-gradient(#eadedaff, #998888ff, #db2955ff, #04a777ff, #2b2d42ff);

  --color-primary: var(--raspberry);
  --color-secondary: var(--space-cadet);
  --color-ternary: rgb(29, 30, 34);
  --color-accent: var(--green-munsell);
  --color-light: var(--platinum);
}

