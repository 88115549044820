@use "../../../tokens/typography";
@use "../../../tokens/variant";

.Heading {
  @include variant.variant;

  &--h1 {
    @include typography.heading1;
  }

  &--h2 {
    @include typography.heading2;
  }

  &--h3 {
    @include typography.heading3;
  }

  &--h4 {
    @include typography.heading4;
  }
}