@use "~sass-mq" as mq;
@use "../../../tokens/mixins";

.Homepage {
  @include mixins.pageLayout;
  grid-template-columns: min-content 1fr;

  @include mq.mq($until: tablet) {
    grid-template-columns: 1fr;
  }

  &__aside {
    padding-top: var(--space-8x);
    padding-left: var(--space-4x);
    position: relative;
    opacity: 0.5;

    &-content {
      position: sticky;
      top: var(--space-2x);
    }

    @include mq.mq($from: tablet) {
      padding-left: var(--space-8x);
      padding-top: var(--space-2x);
      @include mixins.asideSpacer;
    }
  }

  &__main {
    padding: 0 var(--space-4x);

    &-content {
      @include mq.mq($from: tablet) {
        margin-top: var(--space-1x);
        padding-bottom: var(--space-8x);
      }
    }
  }
}
