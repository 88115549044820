@use "~sass-mq" as mq;
@use '../../../tokens/animations';

.Introduction {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding: 0 var(--space-4x);
  @include animations.opacityAnimation(var(--longAnimation));

  @include mq.mq($until: tablet) {
    grid-template-columns: 1fr;
    padding: var(--space-8x) var(--space-4x);
  }
}