.TechnologiesList {
  display: grid;
  width: fit-content;
  grid-auto-flow: column;
  grid-gap: var(--space-4x);

  &__technology {
    font-size: .7rem;
    color: var(--color-primary);
  }
}