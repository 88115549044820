html {
  font-size: 24px;
}

body {
  margin: 0;
  font-family: var(--font-primary);
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--color-ternary);
}
