.Spinner {
}

.ApplicationSpinner {
  height: 100vh;
  width: 100vw;
  //background: var(--color-primary);
  z-index: 1;

  @keyframes ApplicationSpinnerAnimation {
    0% {
      transform: scaleY(1);
    }
    100% {
      transform: scaleY(0);
    }
  }
  //animation: ApplicationSpinnerAnimation;
  //animation-duration: .1s;
  //animation-fill-mode: both;
  //transform-origin: bottom;

}
