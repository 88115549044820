.ProjectsList {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--space-16x) 0;
  padding-top: var(--space-2x);
}

.ProjectsListItem {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--space-2x) 0;

  &__technologies {
    margin-top: calc(-1 * var(--space-2x));
  }
}
