@use "~sass-mq" as mq;
@use "../../../tokens/mixins";

.Project {
  @include mixins.pageLayout;
  grid-template-columns: max-content 1fr;
  padding-top: var(--space-8x);
  grid-gap: var(--space-8x);

  &__navigation {
    position: sticky;
    top: var(--space-8x);
    height: fit-content;
  }

  &__title {
    margin-top: calc(-1 * var(--space-2x));
  }

  &__content {
    display: grid;
    grid-gap: var(--space-4x);
  }
}
