@use "../../../tokens/typography";

.Link {
  @include typography.link;
  width: fit-content;

  &--hoverEffect {
    @include typography.linkHover;
  }
}
